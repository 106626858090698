@import "~@/styles/variables";


















































































































































.add-rx-review-header {
  font-family: $flama;
}

.item-header {
  color: $pp-blue-grey;
  text-transform: uppercase;
  font-weight: $f-weight-medium;
}

.submit-message {
  background: $pp-snowball;
}
